<template>
  <div class="page">
    <div class="topDiv">
      <Form inline class="form formMarginBtm20">
        <FormItem>
          <span class="label">供应商名称：</span>
          <Select filterable clearable v-model="queryList.supplier_id" class="iviewIptWidth250 marginRight50">
            <Option v-for="item in supplierList" :value="item.supplier_id" :key="item.supplier_id">{{ item.supplier_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span class="label">厂家名称：</span>
          <Select filterable clearable v-model="queryList.search_str" class="iviewIptWidth250 marginRight50">
            <Option v-for="item in nameList" :value="item.name" :key="item.subfactory_id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span class="label marginLeft30px">营业执照有效期：</span>
          <DatePicker @on-change="changeTime($event, 1)" format="yyyy-MM-dd" type="date" class="iviewIptWidth250" placeholder="请选择" clearable></DatePicker>
          <span class="centeFont">至</span>
          <DatePicker clearable v-model="queryList.business_date_end" :options="options" @on-change="changeTime($event, 2)" format="yyyy-MM-dd" type="date" class="iviewIptWidth250 marginRight90" placeholder="请选择"></DatePicker>
        </FormItem>
        <FormItem>
          <span class="label">状态：</span
          ><Select clearable v-model="queryList.state" class="iviewIptWidth250">
            <Option v-for="item in statList" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number" :label-width="20">
          <span class="pageBtn finger btnSure" @click="search()">查询</span>
        </FormItem>
      </Form>
    </div>
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}({{ item.num }})
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="tabelDiv">
      <Table :productList="listColumns" :productData="listData" :total="total" :pages="pages" @on-sort-change="sort" @change-page="changePage" :isLoad="isLoad" totalText="条记录">
        <template slot-scope="{ row, index }" slot="supplier_name">
          <div class="flle">
            <Poptip trigger="hover" word-wrap width="250" :content="row.supplier_list">
              <span>{{ row.supplier_name }}</span>
            </Poptip>
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="factory_num">
          <div>
            <span class="fl">¥ </span>
            <i-input class="fl" :value="row.factory_num" maxlength="8" placeholder="请输入" style="width: 100px;">{{ index }}</i-input>
          </div>
        </template>
      </Table>
    </div>
    <!-- 禁用模态框 -->
    <Modal v-model="OffStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认禁用？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="turnOff">确定</span>
          <span class="pageBtn finger btnCancle" @click="OffStatus = false">取消</span>
        </div>
      </div>
    </Modal>

    <!-- 启用模态框 -->
    <Modal v-model="OnStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认启用？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="turnOn">确定</span>
          <span class="pageBtn finger btnCancle" @click="OnStatus = false">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'supplierManage',
  components: {
    Table,
  },
  computed: {},
  data() {
    return {
      key: null,
      order: null,
      isLoad: true,
      nameList: [],
      statList: [
        {
          value: '1',
          label: '新增',
        },
        {
          value: '2',
          label: '修改',
        },
        {
          value: '3',
          label: '有效',
        },
        {
          value: '5',
          label: '近效期',
        },
        {
          value: '4',
          label: '过期',
        },
        {
          value: '0',
          label: '禁用',
        },
        {
          value: '6',
          label: '审核驳回',
        },
      ],
      navFrist: [
        {
          name: '全部',
          num: '',
          type: 0,
        },
        {
          name: '待审核',
          num: '',
          type: 1,
        },
        {
          name: '已审核',
          num: '',
          type: 2,
        },
        {
          name: '审核驳回',
          num: '',
          type: 3,
        },
      ],
      clickIndex: 0,
      listColumns: [
        {
          title: '序号',
          key: 'indexs',
          align: 'center',
          minWidth: 70,
          // render: (h, param) => {
          //   return h('div', [
          //     h(
          //       'span',
          //       {
          //         class: 'tableBlakcFont',
          //         on: {
          //           click: () => {
          //             console.log(param)
          //           },
          //         },
          //       },
          //       param.index + 1
          //     ),
          //   ])
          // },
        },
        {
          title: '厂家编号',
          key: 'factory_num',
          align: 'center',
          minWidth: 120,
          sortable: 'custom',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.factory_num
              ),
            ])
          },
        },
        {
          title: '厂家名称',
          key: 'factory_name',
          align: 'center',
          minWidth: 220,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'tableFont content_left',
                  on: {
                    click: () => {
                      console.log(param.row.factory_id)
                      // 传递一个工厂id值
                      this.goDetails(param.row.factory_id, this.Byname, param.row.factory_type, param.row.subfactory_id)
                    },
                  },
                },
                param.row.factory_name
              ),
            ])
          },
        },
        {
          title: '供应商名称',
          // key: 'supplier_name',
          slot: 'supplier_name',
          align: 'center',
          minWidth: 220,
          // render: (h, param) => {
          //   return h('div', [
          //     h(
          //       'div',
          //       {
          //         class: 'TextAlign_lf',
          //       },
          //       param.row.supplier_name
          //     ),
          //   ])
          // },
        },
        {
          title: '营业执照有效期',
          key: 'factory_business',
          align: 'center',
          sortable: 'custom',
          minWidth: 120,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.factory_business
              ),
            ])
          },
        },
        {
          title: '生产许可证有效期',
          key: 'factory_product',
          align: 'center',
          minWidth: 140,
          sortable: 'custom',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.factory_product
              ),
            ])
          },
        },
        {
          title: '备案日期',
          key: 'factory_filing',
          align: 'center',
          minWidth: 100,
          sortable: 'custom',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.factory_filing
              ),
            ])
          },
        },
        {
          title: '类型',
          key: 'factory_type',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.factory_type
              ),
            ])
          },
        },
        {
          title: '状态',
          key: 'factory_state',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            let str = param.row.factory_state
            switch (str) {
              case '新增':
                return h('div', [
                  h(
                    'span',
                    {
                      class: 'tableBlakcFont',
                    },
                    str
                  ),
                ])
              case '修改':
                return h('div', [
                  h(
                    'span',
                    {
                      class: 'tableBlakcFont',
                    },
                    str
                  ),
                ])
              case '近效期':
                return h('div', [
                  h(
                    'span',
                    {
                      class: 'tableOrangeFont',
                    },
                    str
                  ),
                ])
              case '过期':
                return h('div', [
                  h(
                    'span',
                    {
                      class: 'tableRedFont',
                    },
                    str
                  ),
                ])
              case '有效':
                return h('div', [
                  h(
                    'span',
                    {
                      class: 'tableGreenFont',
                    },
                    str
                  ),
                ])
              case '禁用':
                return h('div', [
                  h(
                    'span',
                    {
                      class: 'tableBlakcFont',
                    },
                    str
                  ),
                ])
              case '审核驳回':
                return h('div', [
                  h(
                    'span',
                    {
                      class: 'tableBlakcFont',
                    },
                    str
                  ),
                ])
            }
          },
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          fixed: 'right',
          minWidth: 150,
          render: (h, param) => {
            let status = param.row.factory_state
            switch (status) {
              case '新增':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        disableUse: this.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.goDetails(param.row.factory_id, this.Byexamination, param.row.factory_type, param.row.subfactory_id)
                        },
                      },
                    },
                    param.row.operation[0]
                  ),
                ])
              case '修改':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        disableUse: this.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.goDetails(param.row.factory_id, this.Byexamination, param.row.factory_type, param.row.subfactory_id)
                        },
                      },
                    },
                    param.row.operation[0]
                  ),
                ])
              case '有效':
                return h('div', [
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.goDetails(param.row.factory_id, this.Byexamination, param.row.factory_type, param.row.subfactory_id)
                        },
                      },
                    },
                    '编辑'
                  ),
                  h(
                    'span',
                    {
                      class: {
                        tableLeft: true,
                        tableFont: true,
                        disableUse: this.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.OffStatus = true
                          this.BandId = param.row.subfactory_id
                        },
                      },
                    },
                    param.row.operation[0]
                  ),
                  h(
                    'span',
                    {
                      class: 'tableFont tableLeft',
                      on: {
                        click: () => {
                          this.export(param.row)
                        },
                      },
                    },
                    param.row.operation[1]
                  ),
                ])
              case '过期':
                return h('div', [
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.modifyAble,
                      },
                      style: {
                        marginRight: '20px',
                      },
                      on: {
                        click: () => {
                          this.goDetails(param.row.factory_id, this.Byexamination, param.row.factory_type, param.row.subfactory_id)
                        },
                      },
                    },
                    '编辑'
                  ),
                  h(
                    'span',
                    {
                      class: 'tableFont',
                      on: {
                        click: () => {
                          this.OffStatus = true
                          this.BandId = param.row.subfactory_id
                        },
                      },
                    },
                    param.row.operation[0]
                  ),
                ])
              case '近效期':
                return h('div', [
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.goDetails(param.row.factory_id, this.Byexamination, param.row.factory_type, param.row.subfactory_id)
                        },
                      },
                    },
                    '编辑'
                  ),
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        tableLeft: true,
                        tableFont: true,
                        disableUse: this.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.OffStatus = true
                          this.BandId = param.row.subfactory_id
                        },
                      },
                    },
                    param.row.operation[0]
                  ),
                  h(
                    'span',
                    {
                      class: 'tableFont tableLeft',
                      on: {
                        click: () => {
                          this.export(param.row)
                        },
                      },
                    },
                    param.row.operation[1]
                  ),
                ])
              case '禁用':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        disableUse: this.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.OnStatus = true
                          this.BandId = param.row.subfactory_id
                        },
                      },
                    },
                    param.row.operation[0]
                  ),
                ])
            }
          },
        },
      ],
      BandId: '',
      listData: [],
      Byname: '1',
      Byexamination: '0',
      total: 1,
      pages: {
        page: 1,
        rows: 10,
      },
      // 获取列表请求参数存储
      form: {
        page: 1,
        rows: 10,
        search_str: '', // 搜索条件
        business_data_start: '', // 营业执照起始有效期
        business_date_end: '', // 营业执照截止有效期
        state: '', // 状态条件
        examine: '', // 审核条件
        // type: 1, // 厂家类型 1=国内厂家 2=境外厂家
      },

      // 提交查询提交数据
      queryList: {
        page: 1,
        rows: 10,
        supplier_id: '',
        search_str: '', // 搜索条件
        business_date_start: '', // 营业执照起始有效期
        business_date_end: '', // 营业执照截止有效期
        state: '', // 状态条件
        examine: '', // 审核条件
      },
      options: {},
      // 控制禁止弹出是否弹出
      OffStatus: false,
      // 控制启用弹窗
      OnStatus: false,

      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
      supplierList: [],
    }
  },
  methods: {
    // 供应商下拉
    pickingPrductModelName() {
      this.$http.get(this.$api.pickingPrductModelName, {}, false).then(res => {
        this.supplierList = res.data
      })
    },
    sort(data) {
      console.log(data)
      this.key = data.key
      this.order = data.order
      this.getsupliyList()
    },
    // 导出审批表操作
    export(row) {
      console.log(row)
      this.$http.downFile(this.$api.exportFactory, { subfactory_id: row.subfactory_id }, true).then(res => {
        let csvData = new Blob([res.data], { type: 'application/x-xlsx' }) // response.data要导出的内容
        let file_name = '厂家审批表' + '.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(csvData, file_name)
        } else {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(csvData)
          a.href = url
          // eslint-disable-next-line camelcase
          a.download = file_name
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
      // this.$Message.error('该功能正在开发中')
    },
    // 时间改变
    changeTime(e, num) {
      console.log(e, 'e')
      console.log(num, 'num')
      if (num == 1) {
        let that = this
        that.queryList.business_date_start = e
        that.queryList.business_date_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryList.business_date_start)
          },
        }
      } else {
        this.queryList.business_date_end = e
      }
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = !rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },

    // 点击启用
    turnOn() {
      let data = {
        subfactory_id: this.BandId,
      }
      this.$http.put(this.$api.factoryManageStateChange, data, false).then(res => {
        this.OnStatus = false
        this.getsupliyList()
      })
    },
    // 点击禁止模态框确认按钮
    turnOff() {
      let data = {
        subfactory_id: this.BandId,
      }
      this.$http.put(this.$api.factoryManageStateChange, data, false).then(res => {
        this.OffStatus = false
        this.getsupliyList()
      })
    },
    ship() {
      this.$router.push('/manufacturerManage')
    },
    goDetails(id, status, type, subfactory_id) {
      if (type == '国内厂家') {
        this.$router.push({
          path: '/manufacturerManage',
          query: {
            id: id,
            status: status,
            subfactory_id: subfactory_id,
          },
        })
      }
      if (type == '境外厂家') {
        this.$router.push({
          path: '/outlandManage',
          query: {
            id: id,
            status: status,
            subfactory_id: subfactory_id,
          },
        })
      }
    },
    changePage(e) {
      this.pages.page = e
      this.getsupliyList()
    },
    clickNav(type) {
      this.clickIndex = type
      console.log(this.clickIndex)
      this.pages.page = 1
      this.pages.rows = 10
      this.getsupliyList()
    },
    // 获取厂家下拉列表
    getListSupList() {
      this.$http.get(this.$api.factoryListBusiness, true).then(res => {
        this.nameList = res.data
      })
    },
    // 获取厂家列表
    getsupliyList() {
      // 带提交数据
      let data = {
        page: this.pages.page, // 页
        rows: this.pages.rows, // 行
        supplier_id: this.queryList.supplier_id, // 供应商名称
        search_str: this.queryList.search_str, // 搜索条件
        business_date_start: this.queryList.business_date_start, // 营业执照起始有效期
        business_date_end: this.queryList.business_date_end, // 营业执照截止有效期
        state: this.queryList.state, // 状态条件
        examine: this.clickIndex, // 审核条件
        sort_str: this.key,
        sort_type: this.order,
      }
      if (this.$moment(data.business_date_start).valueOf() > this.$moment(data.business_date_end).valueOf()) {
        this.$Message.warning('截止有效期不得早于起始有效期，请重新选择截止有效期')
        return
      }
      this.isLoad = true
      // 发送请求
      this.$http.get(this.$api.factoryManageBusiness, data, true).then(res => {
        this.isLoad = false
        this.listData = res.data.factorys
        this.total = res.data.total
        this.navFrist[0].num = res.data.all_total
        this.navFrist[1].num = res.data.wait_count
        this.navFrist[2].num = res.data.already_count
        this.navFrist[3].num = res.data.back_count
        this.listData.forEach((item, index) => {
          // 处理列表中的时间日期
          if (item.factory_business != '长期') {
            item.factory_business = this.$moment.unix(item.factory_business).format('YYYY-MM-DD')
          }
          item.factory_filing = this.$moment.unix(item.factory_filing).format('YYYY-MM-DD')
          item.factory_product = this.$moment.unix(item.factory_product).format('YYYY-MM-DD')
          // 根据时间追加判断
          if (item.factory_business == '1970-01-01') {
            item.factory_business = ''
          }
          if (item.factory_filing == '1970-01-01') {
            item.factory_filing = ''
          }
          if (item.factory_product == '1970-01-01') {
            item.factory_product = ''
          }
          // 增加序号
          item.indexs = index + 1 + (this.pages.page - 1) * 10
          // 处理空值到话用/代替
          for (var key in item) {
            if (!item[key]) {
              item[key] = '/'
            }
          }
        })
      })
    },
    search() {
      this.pages.page = 1
      this.getsupliyList()
    },
  },
  activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      this.getsupliyList()
      sessionStorage.setItem('updataCache', '1')
    }
  },
  mounted() {
    this.getsupliyList()
    this.getListSupList()
    this.getRightVisibal()
    this.pickingPrductModelName()
  },
}
</script>

<style scoped lang="less">
.fontSize14px {
  font-size: 14px;
}
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight90 {
  margin-right: 90px;
}
.marginLeft30px {
  margin-left: 30px;
}
.centeFont {
  margin: 0 10px;
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
.flle {
  float: left;
  text-align: left;
}
.marginRight50 {
  margin-right: 50px;
}
</style>
